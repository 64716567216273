@import './defaults.scss';

.signup {
    
}

.signupTitle {
    font-size: 30px;

    @include largeDesktop {
        font-size: 40px;
    }

    @include wideScreen {
        font-size: 55px;
    }
}

.signupBlock {
    // margin: 3vw 5vw;
    width: 100vw;
    text-align: left;
    // background-color: red;
}

.signupLabel {
    margin: 0 0 0 5%;
    font-size: 16px;

    @include tablet {
        margin: 0 0 0 30%;
    }

    @include laptop {
        margin: 0 0 0 35%;
    }

    @include desktop {
        font-size: 20px;
        margin: 0 0 0 40%;
    }

    @include largeDesktop {
        font-size: 30px;
        margin: 0 0 0 37.5%;
    }

    @include wideScreen {
        font-size: 34px;
    }
}

.signupInput {
    display: block;
    width: 90%;
    height: 40px;
    margin: 5px 0 5vh 5%;
    font-size: 16px;

    @include tablet {
        width: 40%;
        margin: 10px 0 5vh 30%;
    }

    @include laptop {
        width: 30%;
        margin: 10px 0 5vh 35%;
    }

    @include desktop {
        width: 20%;
        margin: 10px 0 5vh 40%;
    }

    @include largeDesktop {
        width: 25%;
        margin: 10px 0 5vh 37.5%;
        font-size: 28px;
        height: 60px;
    }

    @include wideScreen {
        height: 70px;
        font-size: 32px;
    }
}

.signupButton {
    width: 40%;
    height: 40px;
    background-color: var(--cyan-blue);
    border: none;
    font-size: 16px;
    color: white;

    @include tablet {
        width: 15%;
    }

    @include laptop {
        width: 12%;
    }

    @include desktop {
        width: 10%;
    }

    @include largeDesktop {
        font-size: 25px;
        height: 60px;
    }

    @include wideScreen {
        font-size: 30px;
        height: 70px;
    }
}

.loginText {
    a {
        color: var(--blue-magenta);
        text-decoration: underline;
    }

    font-size: 16px;

    @include desktop {
        font-size: 18px;
    }

    @include largeDesktop {
        font-size: 24px;
    }
    
    @include wideScreen {
        font-size: 32px;
    }
}