@import './defaults.scss';

.account {

    font-size: 16px;

    @include desktop {
        font-size: 18px;
    }

    @include largeDesktop {
        font-size: 24px;
    }

    @include wideScreen {
        font-size: 32px;
    }
}

.accountTitle {
    font-size: 26px;

    @include desktop {
        font-size: 32px;
    }

    @include largeDesktop {
        font-size: 38px;
    }

    @include wideScreen {
        font-size: 56px;
    }
}

.usernameLabel {
    // border: 1px solid black;
    padding: .5em;
    margin: 0 1em 0 0;
    text-decoration: underline;

}

.username {
}

.signoutButton {
    display: block;
    margin: 3em auto;
    width: 10em;
    height: 3em;
    background-color: rgb(179, 5, 5);
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;

    @include largeDesktop {
        font-size: 24px;
    }

    @include wideScreen {
        font-size: 30px;
    }
}