@mixin largePhones {
    @media only screen and (min-width: 321px) { @content; }
  }
  
@mixin tablet {
  @media only screen and (min-width: 768px) { @content; }
}

@mixin laptop {
  @media only screen and (min-width: 1024px) { @content; }
}

@mixin desktop {
  @media only screen and (min-width: 1440px) { @content; }
}

@mixin largeDesktop {
  @media only screen and (min-width: 1900px) { @content; }
}

@mixin wideScreen {
  @media only screen and (min-width: 2560px) { @content; }
}

html {
    background-color: var(--cyan-blue);

    --gray: linear-gradient(351deg, rgba(180,180,180,1) 10%, rgba(200,200,200,1) 90%);

    // color wheel colors

    --cyan: #00ffff;

    // ///////////////////////////////////////////
    // --cyan-blue: #0077ff;

    // --blue: #0000ff;

    // --blue-magenta: #7700ff;
    // ///////////////////////////////////////////


    ///////////////////////////////////////////
    // --cyan-blue: #0386ae;
    --blue-magenta: #02a145;

    --blue: #02a145;

    --cyan-blue: #04461f;
    // --blue-magenta: #020ba9;
    ///////////////////////////////////////////

    

    --magenta: #ff00ff;

    --magenta-red: #ff0077;

    --red: #ff0000;

    --red-yellow: #ff7700;

    --yellow: #ffff00;

    --yellow-green: #77ff00;

    --green: #00ff00;

    --green-cyan: #00ff77;

    overflow-x: hidden;
}

body {
    overflow-x: hidden;
    font-family: 'Trebuchet MS', sans-serif;
}

.mainPage {
    width: 100vw;
    min-height: 87vh;
    height: max-content;
    overflow-x: hidden;
    position: relative;
    background-color: rgb(245, 245, 245);
}

.pageTitle {
  font-size: 36px;
  margin: .5em auto;
  

  @include largeDesktop {
      font-size: 45px;
  }

  @include wideScreen {
      font-size: 60px;
  }
}