@import './defaults.scss';

.leagueCard {
    width: 90%;
    height: max-content;
    margin: 1em auto 2em auto;
    border: 1px solid black;
    position: relative;
    border-radius: 20px;
    display: block;
    background-color: rgb(230, 230, 230);
}

.leagueTitleDiv {
    width: 100%;
    // height: 5em;
    display: flex;
    justify-content: space-around;
    // border: 1px solid black;
}

.leagueTitleDiv .leagueName {
    display: inline-block;
    width: max-content;
    height: max-content;
    text-align: left;
    // margin: 2rem 0 0 5%;
    // padding-top: 1rem;
    margin: .5em auto;
    font-size: 24px;

    @include desktop {
        font-size: 26px;
    }

    @include largeDesktop {
        font-size: 30px;
    }

    @include wideScreen {
        font-size: 50px;
    }
}

.leaguePlayersDiv {
    overflow-x: auto;
}

.leaguePlayersDiv .row {
    width: max-content;
    display: flex;
    text-align: center;
    margin: 1em 1em;
    border-radius: 10px;
    border: 1px solid black;
    box-shadow: 2px 2px 5px black;
    font-size: 14px;
    min-width: max-content;

    @include tablet {
        width: 95% !important;
        margin: 1em 1em;
    }

    @include laptop {
        margin: 1em auto;
    }

    @include desktop {
        font-size: 16px;
    }

    @include largeDesktop {
        font-size: 20px;
    }

    @include wideScreen {
        font-size: 28px;
    }
}

.leaguePlayersDiv .playersCardHeaders {
    // border: 1px solid black;
    background-color: var(--blue);
    box-shadow: none;
    border: none;
}

.leaguePlayersDiv .leagueHeader {
    display: inline;
    // width: 16.6666%;
    min-width: 9.5em;   
    // background-color: red;
    color: white;
    cursor: pointer;
    // border: 1px solid black;

    &.selected {
        text-decoration: underline;
    }
}

.leaguePlayersDiv .playerCardRow {
    cursor: pointer;
    background-color: white;
}

.leaguePlayersDiv .playerCardData {
    display: inline;
    min-width: 9.5em;
    // width: 16.666%;
    // border: 1px solid black;
}

h4 {
    margin-left: .1em;
    margin-right: .1em;
}