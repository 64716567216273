.footer {
    background-color: var(--cyan-blue);
    position: relative;
    bottom: 0;
    width: 100%;
    color: white;

    a {
        color: white;
    }
}

.footer p {
    padding: 10px 0 0 0;
    color: white;
}
